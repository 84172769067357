module.exports = {
    standData: [
        {
            value: 'ua',
            label: '北美站',
            children: [{
                label: '美国',
                value: 'us'
            }, {
                label: '加拿大',
                value: 'can'
            },
            {
                label: '墨西哥',
                value: 'mx'
            },
            {
                label: '巴西',
                value: 'br'
            }]
        },
        {
            value: 'eurp',
            label: '欧洲站',
            children: [{
                label: '英国',
                value: '英国',
            }, {
                label: '德国',
                value: '德国'
            },
            {
                label: '西班牙',
                value: '西班牙'
            },
            {
                label: '法国',
                value: '法国'
            },
            {
                label: '意大利',
                value: ''
            },
            {
                label: '荷兰',
                value: ''
            },
            {
                label: '土耳其',
                value: ''
            },
            {
                label: '阿联酋',
                value: ''
            },
            {
                label: '沙特阿拉伯',
                value: ''
            },
            {
                label: '印度',
                value: ''
            },
            {
                label: '波兰',
                value: ''
            },
            {
                label: '瑞典',
                value: ''
            }
            ]
        },
        {
            value: 'Japan',
            label: '日本站',
            children: [{
                label: '日本',
                value: ''
            }]
        },
        {
            value: 'Singapore站',
            label: '新加坡站',
            children: [{
                label: '新加坡',
                value: ''
            }]
        },
        {
            value: 'Australia',
            label: '澳洲站',
            children: [{
                label: '澳大利亚',
                value: ''
            }]
        },
    ],
    countryMap: {
        'us': "美国",
        "can": '加拿大',
        "mx": "墨西哥",
        "br": "巴西"
    }
} 
