
import { store } from 'src/views/store';
import { bindActionCreators } from 'redux';
import * as actions from 'src/components/Chart/chartAction';
import { connect } from 'react-redux';
import {
    toggleExpandSlice,
    setFocusedFilterField,
    unsetFocusedFilterField,
    setFullSizeChartId
} from 'src/dashboard/actions/dashboardState';
import { updateComponents } from 'src/dashboard/actions/dashboardLayout';
import { changeFilter } from 'src/dashboard/actions/dashboardFilters';
import {
    addSuccessToast,
    addDangerToast,
} from 'src/components/MessageToasts/actions';
import { addChart, refreshChart } from 'src/components/Chart/chartAction';
import { logEvent } from 'src/logger/actions';
import Chart from 'src/dashboard/components/gridComponents/Chart';


// addChart({
//     id: 1111,
//     chartAlert: null,
//     chartStatus: 'loading',
//     chartStackTrace: null,
//     chartUpdateEndTime: null,
//     chartUpdateStartTime: 0,
//     latestQueryFormData: {},
//     sliceFormData: null,
//     queryController: null,
//     queriesResponse: null,
//     triggerQuery: true,
//     lastRendered: 0,
// }, 1111)(store.dispatch);
function mapStateToProps(
    state: any,
    ownProps: any
) {
    const { id, slice_id, formData, slice_name, isSelectedOperate, isMongoDataTable, isPresetData, chatChartConfig } = ownProps;
    if(!formData) return;
    if ((isMongoDataTable || isPresetData) && !state.charts[id]) {
        const label_map = {};
        if (isMongoDataTable) {
            formData.columns.forEach((i: string) => {
                label_map[i] = [i];
            });
        }
        const chart = {
            id,
            chartAlert: null,
            chartStatus: 'success',
            chartStackTrace: null,
            chartUpdateEndTime: null,
            chartUpdateStartTime: 0,
            latestQueryFormData: formData,
            sliceFormData: null,
            queryController: null,
            queriesResponse: isPresetData ? formData.queriesResponse : [{
                colnames: formData.columns,
                coltypes: formData.columns.map((i: string) => typeof formData.results?.[0]?.[i] === 'number' ? 0 : 1),
                data: formData.results,
                label_map,
            }],
            triggerQuery: false,
            lastRendered: 0,
        };
        addChart(chart, id)(store.dispatch);
        return {
            ...ownProps,
            chart,
            chartId: id,
            ...chart,
            id,
            viz_type: formData.viz_type,
            vizType: formData.viz_type,
            slice: {
                chatChartConfig,
                viz_type: formData.viz_type,
                slice_name,
                slice_id: slice_id || id,
            },
            sliceName: slice_name,
            height: 270,
            width: 400,
            fullSizeChartId: state.dashboardState.fullSizeChartId,
            handleToggleFullSize: () => {
                setFullSizeChartId(id)
            }
        };
    }
    // debugger;
    if (!state.charts[id]) {
        addChart({
            id,
            chartAlert: null,
            chartStatus: 'loading',
            chartStackTrace: null,
            chartUpdateEndTime: null,
            chartUpdateStartTime: 0,
            latestQueryFormData: {},
            sliceFormData: null,
            queryController: null,
            queriesResponse: null,
            triggerQuery: true,
            lastRendered: 0,
        }, id)(store.dispatch);
        return {};
    }

    // const formDataObject = typeof formData ==='object' ? formData : JSON.parse(formData);
    const tt = {
        ...ownProps,
        chart: state.charts[id],
        // formData: {
        //     ...formDataObject,
        //     isCustom: true
        // },
        chartId: id,
        ...state.charts[id],
        id: state.charts[id].id,
        viz_type: formData.viz_type,
        vizType: formData.viz_type,
        slice: {
            chatChartConfig,
            viz_type: formData.viz_type,
            slice_name,
            slice_id: slice_id || id,
        },
        sliceName: slice_name,
        height: 270,
        width: 400,
        fullSizeChartId: state.dashboardState.fullSizeChartId,
        handleToggleFullSize: () => {
            setFullSizeChartId(id)
        }
    };
    // debugger;
    return tt;
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            updateComponents,
            addSuccessToast,
            addDangerToast,
            toggleExpandSlice,
            changeFilter,
            setFocusedFilterField,
            unsetFocusedFilterField,
            refreshChart,
            logEvent,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Chart);

