import React, { useEffect } from 'react';
import '../style/auth.less';
import { Button, message, Modal } from 'antd';
import Clipboard from 'clipboard';

interface Props {
  children?: React.ReactNode;
  visible: boolean;
  cancelShow: () => void;
  url: string;
}

const AuthorFunc: React.FC<Props> = (props): JSX.Element => {
  const { visible, cancelShow, url } = props;
  /* * state * */

  /* * effect * */
  useEffect(() => {
    const clipboard = new Clipboard('#copy-btn');
    clipboard.on('success', (e) => {
      message.success('复制成功');
      e.clearSelection();
    });
  }, []);

  /* * methods * */
  const authClick = () => {
    window.open(url, '_blank');
  };

  /* * render * */
  return (
    <Modal
      width={715}
      title=""
      visible={visible}
      onCancel={cancelShow}
      className="author-func"
      footer={[
        <div className="func-footer">
          <Button onClick={authClick} className="footer-btn" type="primary" size="large">开始授权</Button>
        </div>
      ]}
    >
      <div className="func-contain">
        <div className="func-title">选择授权方式</div>
        <div className="func-panel">
          <div>
            <div className="func-panel-title">前往亚马逊后台授权，在本环境上完成</div>
            <div className="func-panel-tips">店铺授权过程中需登录亚马逊卖家后台，为避免店铺关联，请使用亚马逊主账号授权。同时需确保登录的设备环境是授权店铺的常用环境。</div>
          </div>
          <div>
            <Button onClick={authClick} className="auth-btn" type="primary" size="large">前往授权</Button>
          </div>
        </div>
        <div className="func-panel">
          <div>
            <div className="func-panel-title">复制授权链接，在其他环境完成</div>
            <div className="func-panel-tips">如果你当前环境不是授权店铺的常用环境，请通过复制授权链接，然后在需授权店铺的常用环境，完成授权。</div>
            <div className="func-url">{url}</div>
          </div>
          <div>
            <Button id="copy-btn" data-clipboard-text={url} className="auth-btn" type="primary" size="large">复制授权链接</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AuthorFunc;
