export interface Option {
  value: string;
  label: string;
  children?: Option[];
  isLeaf?: boolean;
  loading?: boolean;
}

export interface ActiveStatus {
  activeStatus: boolean;
  country: string;
  sellerId: string;
}

export interface AuthStatus {
  accountName: string;
  adActiveStatus: Array<ActiveStatus> | null;
  adAuthStatus: boolean;
  adAuthTime: number | null;
  region: string;
  sellerActiveStatus: Array<ActiveStatus> | null;
  sellerAuthStatus: boolean;
  sellerAuthTime: number | null;
  userId: number;
}

export interface FinishOAuthType {
  grantType: string;
  oauthCode: string;
  sellingPartnerId?: string | null;
  state: string;
}

export interface AuthUrlType {
  accountName: string;
  country: Array<string>;
  grantType: string;
  region: string;
  timestamp?: number;
  userId?: number;
}

export interface UpdateActiveType {
  accountName: string;
  activeStatus: boolean;
  country: string;
  grantType: string;
  region: string;
  userId?: number;
}

export enum GrantType {
  seller = 'seller',
  advertise = 'ad',
}

export interface AreaType {
  key: string;
  value: string;
}
