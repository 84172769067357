import React, { useEffect, useState } from 'react';
import "../style/auth.less";
import { Button, Modal, Select, Checkbox, Input, message } from 'antd';
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CheckboxOptionType } from "antd/es/checkbox";
import authorizationGuidance from 'src/assets/images/authorizationGuidance/authorizationGuidance.png';
import authorizationInfo from 'src/assets/images/authorizationGuidance/info.png';
import api from '../api';
import { AreaType, AuthUrlType, GrantType, Option } from '../types/types';
import AuthorFunc from './AuthorFunc';

interface Props {
  children?: React.ReactNode;
  visible: boolean;
  cancelShow: () => void;
  propsAccountName?: string;
  propsRegion?: string;
  propsCountry?: Array<string>;
}

const CheckboxGroup = Checkbox.Group;

const SellerModal: React.FC<Props> = (props): JSX.Element => {
  const { visible, cancelShow, propsAccountName, propsRegion, propsCountry } = props;

  /* * state * */
  const [accountName, setAccountName] = useState<string>(propsAccountName || '');
  const [checkedList, setCheckedList] = useState<Array<CheckboxValueType>>([]);
  const [plainOptions, setPlainOptions] = useState<Array<CheckboxOptionType>>([]);
  const [region, setRegion] = useState<string>(propsRegion || '');
  const [regionList, setRegionList] = useState<Array<Option>>([]);
  const [url, setUrl] = useState<string>('');
  const [funcFlag, setFuncFlag] = useState<boolean>(false);

  const checkAll = plainOptions.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;

  /* * effect * */
  useEffect(() => {
    localStorage.setItem('grantType', GrantType.seller);
  }, []);

  useEffect(() => {
    (async () => {
      const regionList: Array<AreaType> = await api.getRegionList();
      const options: Array<Option> = regionList.map((region: AreaType) => ({
        value: region.key,
        label: region.value,
        isLeaf: false,
      }));
      setRegionList(options);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (propsRegion) {
        const countryList: Array<AreaType> = await api.getCountryList(propsRegion);
        const options = countryList.map((data) => ({
          label: data.value,
          value: data.key,
        }));
        setPlainOptions(options);
      }
      if (propsCountry) {
        setCheckedList(propsCountry);
      }
    })();
  }, []);

  /* * methods * */
  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions.map(l => l.value) : []);
  };

  const handleSelectChange = async (value: string) => {
    setRegion(value);
    const countryList = await api.getCountryList(value);
    const options = countryList.map((data) => ({
      label: data.value,
      value: data.key,
    }));
    setPlainOptions(options);
  }

  const accountHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountName(e.target.value);
  };

  const handleLikeSubmit = async () => {
    try {
      const country = checkedList.map((data) => String(data));
      const data: AuthUrlType = {
        accountName,
        country,
        grantType: 'seller',
        region,
      };
      if (!accountName || accountName === '') {
        message.error('请填写授权信息');
      } else if (country.length === 0) {
        message.error('请填写授权信息');
      } else if (!region || region === '') {
        message.error('请填写授权信息');
      } else {
        const url = await api.getOAuthUrl(data);
        setUrl(url);
        setFuncFlag(true);
      }
    } catch (error) {
      message.error('获取授权链接失败');
    }
  }

  return (
    <Modal title="" width={956} visible={visible} onCancel={cancelShow} footer={null} wrapClassName="authDialog" >
      <div className="auth-start-container">
        <p className="title">从店铺授权开始，迈出精细化运营第一步</p>
        <p className="tips">选择区域站点，点击 前往授权 连接您亚马逊账号并确认授权，将开启数据同步到数觉BI。</p>
        <div className="main-container">
          <div className="img-container">
            <img style={{ width: '100%',height: '100%' }} src={authorizationGuidance} alt="" />
          </div>
          <div className="main-form">
            <div className="form-item">
              <p><span className="label-name">账号名称</span></p>
              <Input defaultValue={propsAccountName || ''} onChange={accountHandle} className="auth-select" size="large" />
            </div>
            <div className="form-item">
              <p><span className="label-name">授权站点</span></p>
              <Select
                size="large"
                defaultValue={propsRegion || ''}
                className="auth-select"
                placeholder="请选择站点"
                onChange={handleSelectChange}
                options={regionList}
                value={region}
              />
            </div>
            {region === '' ? (
              <div />
            ) : (
              <div className="form-item">
                <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                  全选
                </Checkbox>
                <div>
                  <CheckboxGroup options={plainOptions} key={Date.now()} value={checkedList} onChange={onChange} style={{ margin: '10px 0' }} />
                </div>
              </div>
            )}
            <div className="tips-container">
              <div className="warn-tips">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     style={{ marginLeft: '10px' }}>
                  <path
                    d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM12 10C12.2652 10 12.5196 10.1054 12.7071 10.2929C12.8946 10.4804 13 10.7348 13 11V16C13 16.2652 12.8946 16.5196 12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17C11.7348 17 11.4804 16.8946 11.2929 16.7071C11.1054 16.5196 11 16.2652 11 16V11C11 10.7348 11.1054 10.4804 11.2929 10.2929C11.4804 10.1054 11.7348 10 12 10ZM12 9C11.7348 9 11.4804 8.89464 11.2929 8.70711C11.1054 8.51957 11 8.26522 11 8C11 7.73478 11.1054 7.48043 11.2929 7.29289C11.4804 7.10536 11.7348 7 12 7C12.2652 7 12.5196 7.10536 12.7071 7.29289C12.8946 7.48043 13 7.73478 13 8C13 8.26522 12.8946 8.51957 12.7071 8.70711C12.5196 8.89464 12.2652 9 12 9Z"
                    fill="#4B25EA" />
                </svg>
              </div>
              <ul className="warn-text">
                <li className="text-item">授权过程需要登录亚马逊后台，请确保设备环境安全。</li>
                <li className="text-item">新授权店铺的操作人员将自动成为该店铺的主账号。</li>
                <li className="text-item">已授权店铺可随时解绑，数据也将清空并不会保留。</li>
              </ul>
            </div>

          </div>
        </div>
        <div className="foot-form">
          <div>
            <a href="" className="auth-tips">
              <img src={authorizationInfo} alt="" style={{ marginLeft: '5px' }} />
              为什么要进行授权？
            </a>
          </div>
          <div style={{ textAlign: 'right' }}>
            <Button key="submit" type="primary" onClick={handleLikeSubmit} className="auth-btn">
              前往授权
            </Button>
          </div>
        </div>
      </div>
      {funcFlag ? (
        <AuthorFunc url={url} visible={funcFlag} cancelShow={() => { setFuncFlag(false) }} />
      ) : (
        <div />
      )}
    </Modal >
  )
}
export default SellerModal;
