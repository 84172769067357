import { useState } from "react";
import "./auth.less";
import { Button, Modal, Select, Checkbox, message } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { SupersetClient } from "@superset-ui/core";
import { CheckboxOptionType } from "antd/es/checkbox";
import authorizationGuidance from 'src/assets/images/authorizationGuidance/authorizationGuidance.png';
import authorizationInfo from 'src/assets/images/authorizationGuidance/info.png';
import { standData } from "./constants";

interface authStartProps {
    notAuth: boolean;
    cancelShow: () => void;
}
const CheckboxGroup = Checkbox.Group;

function AuthStart({ notAuth, cancelShow }: authStartProps) {

    // 站点数据

    const defaultCheckedList: string[] = [];
    const [accountName, setAccountName] = useState('');
    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(defaultCheckedList);
    const [plainOptions, setPlainOptions] = useState<CheckboxOptionType[]>(standData[0].children);
    const [region, setRegion] = useState<string>('ua');
    const checkAll = plainOptions.length === checkedList.length;
    const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;
    const onChange = (list: CheckboxValueType[]) => {
        setCheckedList(list);
    };
    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        setCheckedList(e.target.checked ? plainOptions.map(l => l.value) : []);
    };
    const handleSelectChange = (value: any) => {
        setRegion(value);
        const selectedOptions = standData.filter(item => item.value === value);
        setPlainOptions(selectedOptions[0]?.children);
    }

    const handleLikeSubmit = () => {
        SupersetClient.post({
            host: `${window.location.host}/extra_server/`,
            endpoint: `/authorize/getOAuthUrl`,
            mode: 'cors',
            // credentials: 'include',
            headers: {
                Accept: '*/*',
                'Content-Type': 'application/json;charset=UTF-8',
            },
            body: JSON.stringify({
                accountName,
                country: checkedList.join(","),
                region,
                grantType: 'seller'
            }),
            parseMethod: 'json',
        })
            .then(({ json }) => {
                if (json.status === 0) {
                    const href = `${location.protocol  }//${  location.host  }${location.pathname}`;
                    location.href = `${decodeURIComponent(json.ret)}&redirect_uri=${decodeURIComponent(href.substring(0, href.length - 1))}`;
                } else {
                    message.error(json.msg);
                }
            })
            .catch(err => {
                console.log(err);

            });
    }
    return (
        <Modal title="" width={956} visible={notAuth} onCancel={cancelShow} footer={null} wrapClassName="authDialog" >
            <div className="auth-start-container">
                <p className="title">从店铺授权开始，迈出精细化运营第一步</p>
                <p className="tips">选择区域站点，点击 前往授权 连接您亚马逊账号并确认授权，将开启数据同步到数觉BI。</p>
                <div className="main-container">
                    <div className="img-container">
                        <img
                            style={{ width: '100%',height: '100%' }}
                        src={authorizationGuidance}
                        alt=""
                      />
                    </div>
                    <div className="main-form">
                        {/* <div className="form-item accout">
                            <p> <span className="lable-name">账号名称</span><strong className="red-star"> *</strong><span className="show-count">{`${accountName.length  }/30`}</span></p>
                            <Input maxLength={30} value={accountName} onChange={(e) => { setAccountName(e.target.value) }} className="input-common" style={{ borderColor: '#919191', width: '100%' }} />
                        </div> */}
                        <div className="form-item">
                            <p> <span className="lable-name">授权站点</span></p>
                            {/* <strong className="red-star"> *</strong> */}
                            <Select
                                style={{ borderColor: '#919191 !important', width: '100%' }}
                                placeholder="请选择站点"
                                optionFilterProp="children"
                                onChange={handleSelectChange}
                                options={standData}
                                value={region}
                            />
                        </div>
                        <div className="form-item">
                            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                                全选
                            </Checkbox>
                            <div>
                                <CheckboxGroup options={plainOptions} key={Date.now()} value={checkedList} onChange={onChange} style={{ margin: '10px 0' }} />
                            </div>
                        </div>
                        <div className="tips-container">
                            <div className="warn-tips">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ marginLeft: '10px' }}>
                                    <path d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM12 10C12.2652 10 12.5196 10.1054 12.7071 10.2929C12.8946 10.4804 13 10.7348 13 11V16C13 16.2652 12.8946 16.5196 12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17C11.7348 17 11.4804 16.8946 11.2929 16.7071C11.1054 16.5196 11 16.2652 11 16V11C11 10.7348 11.1054 10.4804 11.2929 10.2929C11.4804 10.1054 11.7348 10 12 10ZM12 9C11.7348 9 11.4804 8.89464 11.2929 8.70711C11.1054 8.51957 11 8.26522 11 8C11 7.73478 11.1054 7.48043 11.2929 7.29289C11.4804 7.10536 11.7348 7 12 7C12.2652 7 12.5196 7.10536 12.7071 7.29289C12.8946 7.48043 13 7.73478 13 8C13 8.26522 12.8946 8.51957 12.7071 8.70711C12.5196 8.89464 12.2652 9 12 9Z" fill="#4B25EA" />
                                </svg>
                            </div>
                            <ul className="warn-text">
                                <li className="text-item">授权过程需要登录亚马逊后台，请确保设备环境安全。</li>
                                <li className="text-item">新授权店铺的操作人员将自动成为该店铺的主账号。</li>
                                <li className="text-item">已授权店铺可随时解绑，数据也将清空并不会保留。</li>
                            </ul>
                        </div>
                       
                    </div>
                </div>
                <div className="foot-form">
                    <div>
                    <a href="" style={{ color: '#030229', display: 'inline-block', marginTop: '30px',fontSize:'14px',fontWeight:600 }}><img
                        src={authorizationInfo}
                            alt=""
                            style={{ marginLeft: '5px' }}
                      />为什么要进行授权？</a>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                            <Button key="submit" type="primary" onClick={handleLikeSubmit} style={{ width:'132px',height:'45px',padding: '10px 30px', borderRadius: '8px', marginTop: '20px' , background: 'linear-gradient(123.73deg, #005AFF 20.95%, #6C28FF 100%)' }}>
                                前往授权
                            </Button>
                        </div>
               </div>
            </div>
        </Modal >
    )
}
export default AuthStart;