import httpRequest from '../../../utils/httpRequest';
import { AreaType, AuthStatus, AuthUrlType, FinishOAuthType, UpdateActiveType } from '../types/types';

/**
  * @desc 获取站点列表
  * @date 2024/03/04
  */
const getRegionList = async () => {
  const { json } = await httpRequest.post(`/authorize/getRegionList`);
  return json.ret;
};

/**
  * @desc 获取站点下国家列表
  * @date 2024/03/04
  */
const getCountryList = async (region: string) => {
  const { json } = await httpRequest.post(`/authorize/region/${region}/getCountryList`);
  return json.ret as Array<AreaType>;
};

/**
  * @desc 查询授权列表
  * @date 2024/03/04
  */
const queryOAuthList = async (accountName: string, adAuthStatus: boolean | string, country: string, sellerAuthStatus: boolean | string) => {
  const queryList: Array<string> = [];
  if (accountName !== '') queryList.push(`accountName=${accountName}`);
  if (adAuthStatus !== '') queryList.push(`adAuthStatus=${adAuthStatus}`);
  if (sellerAuthStatus !== '') queryList.push(`sellerAuthStatus=${sellerAuthStatus}`);
  if (country !== '') queryList.push(`country=${[country]}`);
  const queryString = queryList.join('&');
  const { json } = await httpRequest.get(`/authorize/queryOAuthList?${queryString}`);
  return json.ret as Array<AuthStatus>;
};

/**
  * @desc 完成授权操作
  * @date 2024/03/05
  */
const finishOAuth = async (data: FinishOAuthType) => {
  const { json } = await httpRequest.post('/authorize/finishOAuth', data);
  return json;
};

/**
  * @desc 获取授权跳转链接
  * @date 2024/03/05
  */
const getOAuthUrl = async (data: AuthUrlType) => {
  const { json } = await httpRequest.post('/authorize/getOAuthUrl', data);
  return json.ret;
};

/**
  * @desc 判断当前用户是否已授权
  * @date 2024/03/05
  */
const hasOAuth = async () => {
  const { json } = await httpRequest.post('/authorize/hasOAuth');
  return json.ret;
};

/**
  * @desc 更新激活状态
  * @date 2024/03/05
  */
const updateActiveStatus = async (data: UpdateActiveType) => {
  const { json } = await httpRequest.put('/authorize/updateActiveStatus', data);
  return json.ret;
};

const api = {
  getRegionList,
  getCountryList,
  queryOAuthList,
  finishOAuth,
  getOAuthUrl,
  hasOAuth,
  updateActiveStatus,
};

export default api;
