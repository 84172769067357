/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { Suspense, useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { GlobalStyles } from 'src/GlobalStyles';
import ErrorBoundary from 'src/components/ErrorBoundary';
import Loading from 'src/components/Loading';
import Menu from 'src/features/home/Menu';
import getBootstrapData from 'src/utils/getBootstrapData';
import ToastContainer from 'src/components/MessageToasts/ToastContainer';
import setupApp from 'src/setup/setupApp';
import setupPlugins from 'src/setup/setupPlugins';
import { routes, isFrontendRoute } from 'src/views/routes';
import { Logger, LOG_ACTIONS_SPA_NAVIGATION } from 'src/logger/LogUtils';
import setupExtensions from 'src/setup/setupExtensions';
import { logEvent } from 'src/logger/actions';
import { store } from 'src/views/store';
import { RootContextProviders } from './RootContextProviders';
import { ScrollToTop } from './ScrollToTop';
import MaskComponent from './mask';
import './app.less';
// import myStore from 'src/utils/store'
// import { Provider } from 'react-redux';
setupApp();
setupPlugins();
setupExtensions();

const bootstrapData = getBootstrapData();
bootstrapData.common.menu_data.menu =
  [
    // {
    //   name: 'Security', icon: 'fa-cogs', label: '安全', childs: [
    //     { name: 'List Users', icon: 'fa-user', label: '用户列表', url: '/user/list/' },
    //     { name: 'List Roles', icon: 'fa-group', label: '角色列表', url: '/roles/list/' },
    //     '-',
    //     { name: 'Action Log', icon: 'fa-list-ol', label: '操作日志', url: '/logmodelview/list/' },
    //     { name: 'Row Level Security', icon: 'fa-lock', label: '行级安全', url: '/rowlevelsecurity/list/' },
    //   ]
    // },
    // {
    //   name: 'Data', icon: '', label: '数据', childs: [
    //     { name: 'Databases', icon: 'fa-database', label: '数据库连接', url: '/databaseview/list/' }]
    // },
    { name: 'Data Advisor',key:'home', icon: 'fa-table', label: 'AI问答', url: '/chat/' },
    { name: 'Charts',key:'chat', icon: 'fa-bar-chart', label: '图卡', url: '/chart/list/?filters=(id:(label:预置图卡,value:true))&favr=1' },
    { name: 'Dashboards',key:'dashboard', icon: 'fa-dashboard', label: '看板', url: '/dashboard/list/?filters=(id:(label:预置看板,value:pre))' },
   
    // { name: 'Datasets', icon: 'fa-table', label: 'Datasets', url: '/tablemodelview/list/' },
   
    // {
    //   name: 'Manage', icon: '', label: '管理', childs: [
    //     { name: 'CSS templates', icon: 'fa-ccs3', label: 'CSS模板', url: '/csstemplatemodelview/list/' },
    //     { name: 'Annotation Layers', icon: 'fa-comment', label: '注释层', url: '/annotationlayer/list/' }]
    // },
    // {
    //   name: 'SQL Lab', icon: 'fa-flask', label: 'SQL', childs: [
    //     { name: 'SQL Editor', icon: 'fa-flask', label: 'SQL Lab', url: '/superset/sqllab/' },
    //     { name: 'Saved Queries', icon: 'fa-save', label: 'Saved Queries', url: '/savedqueryview/list/' },
    //     { name: 'Query Search', icon: 'fa-search', label: 'Query History', url: '/superset/sqllab/history/' }]
    // }
  ]
let lastLocationPathname: string;

const boundActions = bindActionCreators({ logEvent }, store.dispatch);

const LocationPathnameLogger = () => {
  const location = useLocation();
  useEffect(() => {
    // This will log client side route changes for single page app user navigation
    boundActions.logEvent(LOG_ACTIONS_SPA_NAVIGATION, {
      path: location.pathname,
    });
    // reset performance logger timer start point to avoid soft navigation
    // cause dashboard perf measurement problem
    if (lastLocationPathname && lastLocationPathname !== location.pathname) {
      Logger.markTimeOrigin();
    }
    lastLocationPathname = location.pathname;
  }, [location.pathname]);
  return <></>;
};

const App = () => (
  // <Provider store={myStore}>
  <Router>
    <ScrollToTop />
    <LocationPathnameLogger />
    <RootContextProviders>
      <GlobalStyles />
      <Menu
        data={bootstrapData.common.menu_data}
        isFrontendRoute={isFrontendRoute}
      />
      <Switch>
        {routes.map(({ path, Component, props = {}, Fallback = Loading }) => (
          <Route path={path} key={path}>
            <Suspense fallback={<Fallback />}>
              <ErrorBoundary>
                <MaskComponent  path={path}>
                  <Component user={bootstrapData.user} {...props} />
                </MaskComponent>
              </ErrorBoundary>
            </Suspense>
          </Route>
        ))}
      </Switch>
      <ToastContainer />
    </RootContextProviders>
    </Router>
  // </Provider>
);

export default hot(App);
