import { useState } from 'react';
import './auth.less';
import { Button, Modal, Checkbox } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { standData, countryMap } from './constants';
import { SupersetClient } from '@superset-ui/core';
interface authStartProps {
  hasAuth: boolean;
  cancelShow: () => void;
  country: string;
}
const CheckboxGroup = Checkbox.Group;

function AuthEnd({ hasAuth, cancelShow, country }: authStartProps) {
  // 站点数据

  const defaultCheckedList: string[] = [];
  const [accoutName, setAccountName] = useState('');
  const [checkedList, setCheckedList] =
    useState<CheckboxValueType[]>(defaultCheckedList);
  const [plainOptions, setPlainOptions] = useState<string[]>([
    '美国',
    '加拿大',
    '墨西哥',
    '巴西',
  ]);
  const [standSelected, setStandSelected] = useState<string>('ua');
  const checkAll = plainOptions.length === checkedList.length;
  const indeterminate =
    checkedList.length > 0 && checkedList.length < plainOptions.length;
  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
  };
  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions : []);
  };
  const handleSelectChange = (value: any) => {
    setStandSelected(value);
    const selectedOptions = standData.filter(item => {
      return item.value === value;
    });
    setPlainOptions(selectedOptions[0]?.children.map(l => l.name));
  };

  const handleLikeSubmit = () => {
    SupersetClient.post({
      host: `${window.location.host}/extra_server/`,
      endpoint: `/authorize/getOAuthUrl`,
      mode: 'cors',
      // credentials: 'include',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({
        country: 'us',
        userId: '4',
        region: 'ua',
        grantType: 'seller',
      }),
      parseMethod: 'json',
    })
      .then(({ json }) => {
        if (json.status === 0) {
          location.href = json.ret;
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  return (
    <Modal
      title=""
      width={1016}
      visible={hasAuth}
      onCancel={cancelShow}
      footer={[]}
    >
      <div className="auth-start-container">
        <p className="title">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            style={{ marginRight: '20px' }}
          >
            <path
              d="M12.5 0C5.60757 0 0 5.60757 0 12.5C0 19.3924 5.60757 25 12.5 25C19.3924 25 25 19.3924 25 12.5C25 5.60757 19.3924 0 12.5 0ZM10.2163 18.7728L5.3726 13.39L6.80228 12.1034L10.1677 15.8425L18.149 6.33774L19.6238 7.57211L10.2163 18.7728Z"
              fill="#4FDC38"
            />
          </svg>
          恭喜你，已完成店铺授权
        </p>
        <p className="auth-success">成功授权{country == '' ? 0 : country.split(',').length}个国家：{country.split(',').map(key => countryMap[key]).join("，")}</p>
        <div className="tips-container auth-success-tips">
          <div className="warn-tips">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              style={{ margin: '0 16px 0 16px' }}
            >
              <path
                d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM12 10C12.2652 10 12.5196 10.1054 12.7071 10.2929C12.8946 10.4804 13 10.7348 13 11V16C13 16.2652 12.8946 16.5196 12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17C11.7348 17 11.4804 16.8946 11.2929 16.7071C11.1054 16.5196 11 16.2652 11 16V11C11 10.7348 11.1054 10.4804 11.2929 10.2929C11.4804 10.1054 11.7348 10 12 10ZM12 9C11.7348 9 11.4804 8.89464 11.2929 8.70711C11.1054 8.51957 11 8.26522 11 8C11 7.73478 11.1054 7.48043 11.2929 7.29289C11.4804 7.10536 11.7348 7 12 7C12.2652 7 12.5196 7.10536 12.7071 7.29289C12.8946 7.48043 13 7.73478 13 8C13 8.26522 12.8946 8.51957 12.7071 8.70711C12.5196 8.89464 12.2652 9 12 9Z"
                fill="#4B25EA"
              />
            </svg>
          </div>
          <div className="warn-text">
            <p className="text-item">
              · 系统正在同步数据，该过程
              <span style={{ color: '#F90C0C', fontWeight: 'bold' }}>
                可能耗时几小时或几天时间。
              </span>
              不同数据量的同步时长不同，请耐心等候。你可以在授权管理页面刷新查看授权状态结果。
            </p>
            <p className="text-item">
              ·
              订单数据默认从上个月1号开始获取，索赔数据默认获取近18个月，其余数据默认只获取近1个月。
            </p>
          </div>
        </div>
      </div>
      <p style={{ textAlign: 'center', marginTop: '183px' }}>
        数据同步过程中，你可以继续通过DEMO数据体验产品功能使用
      </p>
      <div style={{ textAlign: 'center', marginTop: '25px' }}>
        <Button
          key="submit"
          type="primary"
          size="large"
          onClick={() => {
            cancelShow();
          }}
          style={{
            padding: '4px 20px',
            borderRadius: '4px',
            marginTop: '20px',
          }}
        >
          确认
        </Button>
      </div>
    </Modal>
  );
}
export default AuthEnd;
